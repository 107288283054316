import React, {Component, Fragment} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, Radio } from '@material-ui/core';
import {countries} from "countries-list"
import {isNullUndefined, alphabeticalSorter} from "../util/"

class Editor extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    getOptionsList = (name, phoneCodeFilter) => {
        let sortedCountriesAsList = Object.entries(countries).reduce((newCountries, [code, country]) => {
            newCountries.push({...country, code: code})
            return newCountries;
        }, []).sort((a, b) => alphabeticalSorter(a.name, b.name));
        let newCountries = sortedCountriesAsList.reduce((updatedCountries, country) => {
            updatedCountries[`${country.code}`] = country;
            return updatedCountries;
        }, {})
        switch(name){
            case "country":
                let countryList = [];
                
                for(let code of Object.keys(newCountries)){
                    let countryInfo = {label: newCountries[code].name, value: newCountries[code].name, inputName: name, code: code}
                    if(code === "US"){
                        countryList.splice(1, 0, countryInfo);
                    }else if(code === "GB"){
                        countryList.unshift(countryInfo);
                    }else{
                        countryList.push(countryInfo);
                    }
                }
                return countryList;
            case "phoneCode":
                let allCountryPhoneCodes = Object.entries(newCountries).map(([countryCode, countryInfo]) => {
                    if(countryInfo.phone.includes(",")){
                        let codes = countryInfo.phone.split(",")
                        return {code: countryCode, phone: `+${codes[0]}`, label: `+${codes[0]} (${countryCode})`, value: `+${codes[0]} (${countryCode})`, inputName: name}
                    }else{
                        return {code: countryCode, phone: `+${countryInfo.phone}`, label: `+${countryInfo.phone} (${countryCode})`, value: `+${countryInfo.phone} (${countryCode})`, inputName: name}
                    }
                })

                if(isNullUndefined(phoneCodeFilter)){
                    return allCountryPhoneCodes
                }

                if(phoneCodeFilter.length < 1){
                    return allCountryPhoneCodes
                }

                return allCountryPhoneCodes.filter((country) => country.code === phoneCodeFilter);
            default:
                return []
        }
    }

    render(){
        const {type, name, id, value, required, variant, label, placeholder, multiline, rows, rowsMax, onChange, onFileChange, accept, multiple, checked, error, phoneCodeFilter, disabled} = this.props;

        switch(type){
            case "text":
                return (
                    <TextField 
                        name={name}
                        id={id}
                        value={value}
                        required={required}
                        variant={variant}
                        label={label}
                        onChange={onChange}
                        placeholder={placeholder}
                        multiline={multiline}
                        rows={rows}
                        rowsMax={rows}
                        error={error}
                        disabled={disabled || false}
                    />
                )
            case "select":
                return (
                    <Autocomplete
                        error={error}
                        name={name}
                        value={value}
                        onChange={onChange}
                        required={required}
                        options={this.getOptionsList(name, phoneCodeFilter)}
                        label={label}
                        getOptionLabel={(option) => option.label || value}
                        closeIcon={<React.Fragment />}
                        renderInput={(params) => <TextField {...params} error={error} label={label} placeholder={placeholder} variant={variant} value={value} autocomplete='off' required={required}/>}
                    />
                )
            case "file":
                return (
                    <TextField
                        type={'file'}
                        name={name}
                        id={id}
                        label={placeholder}
                        onChange={onFileChange}
                        variant={variant}
                        value={value}
                        multiple={multiple}
                        required={required}
                        error={error}
                        inputProps={{
                            accept: accept
                        }}
                    />
                )
            case "checkbox":
                return (
                    <FormControlLabel
                        control={<Checkbox
                            name={name}
                            id={id}
                            value={value}
                            variant={variant}
                            onChange={onChange}
                            checked={checked}
                            error={error}
                        />}
                        label={label}>
                    </FormControlLabel>
                )
            case "radio":
                return (
                    <FormControlLabel
                        control={<Radio
                            name={name}
                            id={id}
                            value={value}
                            variant={variant}
                            onChange={onChange}
                            checked={checked}
                            error={error}
                        />}
                        label={label}>
                    </FormControlLabel>
                )
            default:
                return <Fragment />
        }
    }
}

export default Editor